<template>
	<div class="ele-body">
		<div ref="table">
			<div class="charBox">
				<div class="m-15">
					<div class="mb-20 tilte">
						{{this.area_name}}【{{this.store_name}}】
						<!-- 店铺ID:{{this.$route.query.orderId}} -->
						<div class="enter">
							
							<span @click="haneldBack" style="cursor: pointer;">
								<img style="width:18px;position:relative;top:0.3rem" src="./enter.png" alt="返回"> 返回货品列表
							</span>
						</div>
					</div>
					<template>
						<el-tabs v-model="activeName" type="card">
							<el-tab-pane name="first">
								<span slot="label" class="dialoTitle">
									<span class="span-box">
										<span>货品详情</span>
									</span>
								</span>
								<div>
									<div class="detailTitle">
										<el-form :model="searchPro" class="ele-form-search d-flex f-end" @submit.native.prevent>
											<el-form-item label="货品状态:" label-width="80px">
												<el-select v-model="searchPro.status" class="w-150" clearable>
													<el-option v-for="option in statusArr" :value="option.id"
														:key="option.id" :label="option.name"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="货品分类:" label-width="80px">
												<el-select v-model="searchPro.category_id" class="w-150" clearable>
													<el-option v-for="option in categoryList" :value="option.type"
															:key="option.type" :label="option.name"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="货品名称:" label-width="82px">
												<el-input v-model="searchPro.name" placeholder="请输入货品名称" class="input163 mr-20" clearable/>
											</el-form-item>
											<el-form-item>
												<el-button type="primary" @click="handleRefPro()"
													icon="el-icon-search" class="ele-btn-icon">搜索
												</el-button>
												<el-button @click="(searchPro={}) && handleRefPro()">重置
												</el-button>
											</el-form-item>
										</el-form>
									</div>
									<ele-data-table style="width:100%;" ref="storeGoodsList" :data="storeGoodsList"
										:choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row class='mt-20' :stripe=true>
										<template slot-scope="{index}">
											<el-table-column type="selection" width="45" align="center" />
											<el-table-column type="index" :index="index" label="编号" width="60"
												align="center" show-overflow-tooltip />
											<el-table-column prop="category_name" label="货品分类" show-overflow-tooltip min-width="40" />
											<el-table-column prop="name" label="货品名称" show-overflow-tooltip
												min-width="60" />
											<el-table-column prop="images" label="货品图片" show-overflow-tooltip min-width="30">
												<template slot-scope="{row}">
													<el-image style="width:25px;height:25px;" :src="row.images" fit="cover" :preview-src-list="[row.images]">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
												</template>
											</el-table-column>
											<el-table-column prop="price" label="货品售价(元)" show-overflow-tooltip
												min-width="40" />
											<el-table-column prop="original_price" label="货品原价(元)"
												show-overflow-tooltip min-width="40" />
											<el-table-column prop="sort" label="当前库存(个)" show-overflow-tooltip
												min-width="40" />
											<el-table-column prop="day_sale_num" label="本月销量(个)"
												show-overflow-tooltip min-width="40" />
											<el-table-column prop="sales_virtual" label="累计销量(个)"
												show-overflow-tooltip min-width="40" />

											<el-table-column prop="status" label="货品状态" show-overflow-tooltip
												min-width="36" >
												<template slot-scope="{row}">{{ row.goods_status == 1 ?'上架':'下架'}}</template>
											</el-table-column>
											<el-table-column prop="is_recommend" label="是否为推荐商品"
												show-overflow-tooltip min-width="48">
												<template slot-scope="{row}">{{ row.is_recommend == 1 ?'是':'否'}}</template>
											</el-table-column>
											<el-table-column prop="update_time" label="货品更新时间" show-overflow-tooltip min-width="60" />
											<el-table-column label="货品规格" width="130px" align="center" :resizable="false">
												<template slot-scope="{row}">
													<span v-if="row.goods_spec==''">无</span>
													<el-link v-else @click="editBusiness(row)" type="primary" :underline="false">
														查看详情
													</el-link>
												</template>
											</el-table-column>
											<el-table-column label="操作" width="100px" align="center" :resizable="false">
												<template slot-scope="{row}">
													<el-link @click="change_status(row)" :type="row.goods_status == 1?'danger':'primary'"
														:underline="false">{{row.goods_status == 1?'下架':'上架'}}</el-link>
												</template>
											</el-table-column>
										</template>
									</ele-data-table>
								</div>
							</el-tab-pane>
							<el-tab-pane name="second">
								<span slot="label" class="dialoTitle">
									<span class="span-box">
										<span>店铺评价</span>
									</span>
								</span>
								<div class="evalBox">
									<div class="evalLeft">
										<div class="mr-20">
											<div class="score">{{this.commentTotal.star}}</div>
											<div>商家评分</div>
										</div>
										<div>
											<div>
												<el-rate v-model="value1" disabled></el-rate>
											</div>
											<div class="scoreAll">共 {{this.commentTotal.total_number}} 人评分</div>
										</div>
									</div>
									<div class="evalRight">
										<div v-for="(item,index) in commentTotal.store_star_total" :key="index"
											class="evalInner">
											<span style="width: 110px;text-align: right;">{{item.name}}</span>
											<el-progress :percentage="item.total_number" :show-text=false color='#FFD30C'>
											</el-progress>
											<span>{{item.total_number}}人</span>
										</div>
									</div>
								</div>
								<div>
									<div class="detailTitle">
										<div style="width: 100px;">评价详情</div>
										<el-form :model="search" class="ele-form-search"
											style="display:flex;flex-wrap: wrap;" @submit.native.prevent>
											<el-form-item label="评价类型:" label-width="80px">
												<el-select v-model="search.type" placeholder="请选择"
													class="drop-down-third" clearable>
													<el-option v-for="option in typeArr" :value="option.id" :key="option.id" :label="option.name"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="开始时间:" label-width="80px">
												<el-date-picker v-model="search.start_time" type="date"
													placeholder="请选择开始时间" class="input163" clearable>
												</el-date-picker>
											</el-form-item>
											<el-form-item label="结束时间:" label-width="80px">
												<el-date-picker v-model="search.end_time" type="date"
													placeholder="请选择结束时间" class="input163 mr-20" clearable>
												</el-date-picker>
											</el-form-item>
											<el-form-item>
												<el-button type="primary" @click="handleRef()"
													icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
												<el-button @click="(search={})&& handleRef()">重置</el-button>
											</el-form-item>
										</el-form>
									</div>
									<div class="listParent">
										<div v-for="(item,index) in commentList" :key="index" class="list">
											<div class="listInner">
												<div class="imgLeftBox">
													<el-image style="width:56px;height:56px;" :src="item.u_headimg"
														fit="cover" :preview-src-list="[item.u_headimg]">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
												</div>
												<div>
													<div>
														<span>{{item.u_name}}</span>
														<span class="listTime">评价时间：{{item.add_time}}</span>
													</div>
													<div class="listScore">
														<span>评分：</span>
														<el-rate v-model="item.star" disabled></el-rate>
													</div>
													<div class="content">评价内容：{{item.comment}}</div>
													<div class="imgRightBox" v-if="item.images">
														<el-image style="width:88px;height:86px;" :src="item.images"
															fit="cover" :preview-src-list="[item.images]"></el-image>
													</div>
													<div style="display: flex;margin-top: 20px;">
														<div v-for="(tab,index) in item.tab" :key="index" class="tagBox">
															<div>{{item.tab[index]}}</div>
														</div>
													</div>
													<div class="replayBox">
														<div class="replay">
															商家回复： {{item.comment_reply}}
														</div>
														<button @click="commentDelete(item.id)" class="replayBtn">删除评价</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</template>
					<!--商品规格 -->
					<el-dialog v-dialogDrag title="商品规格" :visible.sync="showBusiness" @closed="odor={},effect={}" :destroy-on-close="true" :lock-scroll="false">
						<div style="margin-left: 30px;">
							<!-- 香型 -->
							<div style="font-size: 16px;">{{odor.name}}:</div>
							<div style="display: flex;padding: 20px 0 30px 0;">
								<div v-for="(item,index) in odor.list" :key="index" class="goodsBtn">{{odor.list[index]}}</div>
							</div>
							<!-- 功效 -->
							<div style="font-size: 16px;padding-top: 20px;border-top:1px solid #E3E3E3">{{effect.name}}:</div>
							<div style="display: flex;margin-top: 20px;">
								<div v-for="(item,index) in effect.list" :key="index" class="goodsBtn">{{effect.list[index]}}</div>
							</div>
						</div>
					</el-dialog>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SysUser",
		data() {
			return {
				// table: {
				// 	url: '/Storegoods/store_goods_list',
				// 	where: {}
				// },
				activeName: 'first',
				value1: 4,
				orderId: 0,
				area_name: '',
				store_name: '',
				storeGoodsList: [],
				commentTotal: [],
				commentList: [],
				categoryList:[],
				showBusiness: false,
				editForm: {
					"price": 100,
					"details": "测试"
				},
				search: {},
				searchPro:{},
				choose:[],
				pid: '',
				cid: '',
				aid: '',
				change: 1,
				changePro: false,
				odor:{},
				effect:{},
				typeArr: [{
					id: 4,
					name: '全部'
				},{
					id: 1,
					name: '有图'
				}, {
					id: 2,
					name: '好评'
				}, {
					id: 3,
					name: '差评'
				}],
				statusArr: [{
					id: 1,
					name: '上架'
				},{
					id: 2,
					name: '下架'
				}],
			}
		},
		created() {
			this.orderId = this.$route.query.orderId
			this.area_name = this.$route.query.area_name
			this.store_name = this.$route.query.store_name
			this.$http.get('/StoreComment/commentTotal?store_id=' + this.orderId).then(res => {
				let data = JSON.parse(JSON.stringify(res.data))
				this.commentTotal = data.data
			})
			this.$http.get('/StoreComment/commentList?store_id=' + this.orderId).then(res => {
				this.commentList = res.data.data.data
			})
			this.$http.get('/Storegoods/store_goods_list?store_id=' + this.orderId).then(res => {
				this.storeGoodsList = res.data.data
			})
			this.$http.get('/storegoods/categoryList?store_id=' + this.orderId).then(res => {
				var Data = res.data.data
				var DataArr = []
				for(let i=0;i<Data.length;i++){
					DataArr.push({type:i,name:Data[i].name})
				}
				this.categoryList = DataArr
			})
		},
		mounted() {
		},
		methods: {
			//刷新
			handleRefPro() {
				const loading = this.$loading({lock: true})
				this.searchPro.store_id = this.orderId
				//console.log(this.searchPro)
				this.$http.post('/Storegoods/store_goods_list',this.searchPro).then(res => {
					this.storeGoodsList = res.data.data
					loading.close()
					this.$refs.storeGoodsList.reload();
				})
			},
			//刷新
			handleRef() {
				const loading = this.$loading({lock: true,target: document.querySelector(".arrList")})
				this.search.store_id = this.orderId
				this.$http.post('/StoreComment/commentList',this.search).then(res => {
					loading.close()
					if (res.data.success === true) {
						this.commentList = res.data.data.data
						this.$refs.commentList.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			haneldBack() {
				this.$router.push({
					path: `/system/merchant/productList`
				})
			},
			/*** 货品规格显示*/
			editBusiness(row) {
				this.showBusiness = true
				if(row.goods_spec[0]){
					this.odor = row.goods_spec[0]
				}
				if(row.goods_spec[1]){
					this.effect = row.goods_spec[1]
				}
			},
			/* 更改状态 */
			change_status(row) {
				if (row.goods_status == 2) {
					this.$confirm('确认上架？', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('storegoods/update_status', {
							id: row.id,
							status: 1
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								this.handleRefPro()
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
				if (row.goods_status == 1) {
					this.$confirm('确认下架？', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('storegoods/update_status', {
							id: row.id,
							status: 2
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								this.handleRefPro()
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			commentDelete(id){
				this.$confirm('确定删除该评论吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({lock: true});
					this.$http.post('/store_comment/commentDelete', {id:id}).then(res => {
						this.$http.get('/StoreComment/commentList?store_id=' + this.orderId).then(res => {
							this.commentList = res.data.data.data
						})
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '删除成功'
							});
						} else {
							this.$message.error(data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}

		},
	}
</script>
<style scoped>
	.tilte {
		font-size: 16px;
		font-weight: 800;
		border-bottom: 1px solid #EAEAEA;
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.enter {
		color: #33CC99;
		overflow: hidden;
	}

	.evalBox {
		color: #333;
		display: flex;
		border-bottom: 1px solid#EAEAEA;
	}

	.evalBox .evalLeft {
		display: flex;
		line-height: 30px;
		margin: 20px 0;
		flex: 1
	}

	.evalBox .evalLeft .score {
		font-weight: 900;
		font-size: 22px;
	}

	.evalBox .evalLeft .scoreAll {
		margin-top: 10px
	}

	.evalBox .evalRight {
		line-height: 30px;
		margin: 20px 0;
		display: flex;
		flex-wrap: wrap;
		flex: 4
	}

	.evalBox .evalRight .el-progress {
		width: 200px;
		margin: 0 10px
	}

	.mr-20 {
		margin-right: 20px
	}

	.evalInner {
		display: flex;
		align-items: center;
		margin-right: 4rem
	}

	.detailTitle {
		margin: 20px 0 30px;
		font-weight: 800;
		display: flex;
		justify-content: space-between;
	}

	.listParent {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.list {
		width: 100%;
		/* border-right: 1px solid #DDDDDD; */
		/* padding-right: 20px; */
	}

	/* .list:nth-child(2n) {
		margin-left: 20px
	} */

	.listInner {
		display: flex;
		width: 100%;
		margin-bottom: 30px;
		border-bottom: 1px solid #EAEAEA;
		margin-right: 20px
	}

	.listInner .imgLeftBox {
		width: 56px;
		height: 56px;
		margin-right: 15px
	}

	.listInner .listTime {
		margin-left: 10px;
		font-size: 12px
	}

	.listInner .listScore {
		font-size: 12px;
		display: flex;
		margin: 8px 0 15px
	}

	.listInner .listScore>span {
		font-size: 12px;
		display: flex;
	}

	.listInner .imgRightBox {
		width: 88px;
		height: 86px;
		border: 1px solid red;
		margin: 20px 0 15px
	}

	.listInner .tagBox {
		display: inline-block;
	}

	.listInner .tagBox div {
		height: 26px;
		line-height: 26px;
		background: #169BD5;
		margin-right: 10px;
		border-radius: 50px;
		font-size: 12px;
		color: #fff;
		padding: 0 10px
	}

	.replayBox {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: flex-end;
		margin-bottom: 30px
	}

	.replayBtn {
		background: #FF570D;
		color: #fff;
		height: 40px;
		width: 110px;
		text-align: center;
		line-height: 40px;
		border-radius: 4px;
		margin-left: 5rem;
		border:0;
		cursor: pointer;
	}

	.replay {
		width: 438px;
		background: #F2F2F2;
		padding: 10px 20px;
		margin-top: 15px
	}

	.content {
		width: 30rem;
	}

	.ele-block .el-upload-dragger {
		overflow: hidden;
	}

	.ele-body {
		background: #fff;
		margin: 20px;
		padding: 20px
	}

	/* .charBox{width:100%;} */
	.el-tabs__item.is-top.is-active {
		color: #33CC99 !important
	}

	.el-card {
		background: #EDEDED !important
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-tabs--card>.el-tabs__header /deep/ .el-tabs__nav {
		border: 1px solid red !important
	}

	/deep/ .el-collapse {
		border: none
	}

	.el-collapse-item /deep/ .el-collapse-item__header {
		height: 10px !important;
		border: none;
		background: #fff;
	}

	/deep/ .el-icon-arrow-right:before {
		content: ''
	}

	/deep/ .el-tabs--card>.el-tabs__header {
		border: none;
		width: 255px
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
		color: #33CC99;
		flex: 1;
		border-left: 1px solid #33CC99;
		overflow: hidden;
		height: 40px;
		border-bottom: none
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 190px;
		border: 1px solid #33CC99;
		border-bottom: none;
		transform-origin: 0 100%;
		transform: skewX(45deg);
		height: 40px;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		flex: 1;
		border-left: 1px solid #33CC99;
		overflow: hidden;
		height: 40px;
		color: #fff;
		position: relative;
		z-index: 0
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 190px;
		border: 1px solid #33CC99;
		border-bottom: none;
		transform-origin: 0 100%;
		transform: skewX(45deg);
		height: 40px;
		background: #33CC99;
		color: #fff;
	}

	.dialoTitle {
		position: absolute;
		z-index: 999;
		top: 0px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: 1px solid transparent !important
	}
	.goodsBtn{
		border: 1px solid black;border-radius: 4px;margin-right: 20px;height: 36px;width: 90px;text-align: center;line-height: 36px;
	}
</style>
